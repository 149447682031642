import { call, put, takeEvery } from "redux-saga/effects";
import {
  SHOPIFY_RESOURCE_PICKER_CALL_DATA,
  SET_DATA_RESOURCE_PICKER_FROM_SERVER,
} from "../constants/ShopifyResourcePicker"; // /constants/AutoketingAppResourcePicker
import { shopifyResourcePickerCallDataServer } from "./ShopifyResourcePickerService"; // /ShopifyResourcePickerService

export function* shopifyResourcePickerCallData(actions) {
  const { targetType } = actions;
  const response = yield call(shopifyResourcePickerCallDataServer, actions);
  if (response) {
    let pageInfo = {};
    if (targetType === "collections") {
      pageInfo = response.data.collections.pageInfo;
    } else {
      pageInfo = response.data.products.pageInfo;
    }
    yield put({
      type: SET_DATA_RESOURCE_PICKER_FROM_SERVER,
      payload: response,
      pageInfo,
    });
  } else {
    console.log(response);
  }
}

function* ShopifyResourcePicker() {
  yield takeEvery(
    SHOPIFY_RESOURCE_PICKER_CALL_DATA,
    shopifyResourcePickerCallData
  );
}

export default ShopifyResourcePicker;

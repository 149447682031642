import { fromJS } from "immutable";

const defaultState = fromJS({
  dataChange: false,
  saveDisabled: false,
  saveLoading: false,
  required: false,
  try_again: {},
  detailCampaignCurrency: {},
  detailCampaignCurrentPackage: {},
  detailCampaignStatistic30Day: {},
  detailCampaignOldSettings: { id: -1 },
  detailCampaignNewSettings: {
    id: -1,
    dashboard_list_type: ["all"],
    campaign_type: "product",
    dashboard_list_value: {
      list_item_collection: {},
      list_item_product: {},
      list_product_for_collection: {},
    },
    data_dashboard: {
      list_collection_data: {},
      list_product_data: {},
      list_product_for_collection: {},
    },
  },
  handleStatusLoading: false,
  handleStatusResult: -1,
  currentPathname: "",
  resultSaveDetailSettings: { success: null },
  resultDeleteCampaign: { success: null },
  listDiscountCampaign: [],
  discountProcessInfo: {},
  newLoadData: null,
  campaignId: "",
});
const discountCampaignReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_DETAIL_DISCOUNT_CAMPAIGN_NEW_SETTINGS":
      return state
        .setIn(["detailCampaignNewSettings", ...action.set_to], action.data_set)
        .set("dataChange", true)
        .set("required", false);
    case "SET_CAMPAIGN_OPTIMIZE_ID":
      return state.set("campaignId", action.id);
    case "ADD_TARGET_CAMPAIGN_SUCCESS":
      return state.set("campaignId", "");
    default:
      return state;
  }
};
export default discountCampaignReducer;

import { fromJS } from "immutable";
import {
  SHOPIFY_RESOURCE_PICKER_SETTINGS_RESET_STATUS,
  SET_IN_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS,
  SET_NEW_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS,
  SET_IN_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS,
  SET_NEW_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS,
  SET_DATA_RESOURCE_PICKER_FROM_SERVER,
  SET_RESOURCE_PICKER_OPEN,
} from "../constants/ShopifyResourcePicker";

const defaultState = fromJS({
  handleStatusLoading: false,
  handleStatusResult: -1,
  dataResourcePickerFromServer: {},
  dataResourcePickerConvertCollections: {},
  dataResourcePickerConvertProducts: {},
  pageInfo: {},
});

const ShopifyResourcePicker = (state = defaultState, action) => {
  switch (action.type) {
    case SHOPIFY_RESOURCE_PICKER_SETTINGS_RESET_STATUS:
      return state
        .set("handleStatusLoading", false)
        .set("handleStatusResult", 0);
    case SET_DATA_RESOURCE_PICKER_FROM_SERVER:
      return state
        .set("dataResourcePickerFromServer", action.payload)
        .set("handleStatusLoading", false)
        .set("handleStatusResult", 305)
        .set("pageInfo", action.pageInfo);
    case SET_IN_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS:
      return state.setIn(
        ["dataResourcePickerConvertCollections", ...action.set_to],
        action.data_set
      );
    case SET_IN_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS:
      return state.setIn(
        ["dataResourcePickerConvertProducts", ...action.set_to],
        action.data_set
      );
    case SET_NEW_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS:
      return state.set("dataResourcePickerConvertCollections", action.data_set);
    case SET_NEW_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS:
      return state.set("dataResourcePickerConvertProducts", action.data_set);
    case SET_RESOURCE_PICKER_OPEN:
      return state.set("resourcePickerOpen", action.data_set);
    default:
      return state;
  }
};

export default ShopifyResourcePicker;

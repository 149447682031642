export const SHOPIFY_RESOURCE_PICKER_CALL_DATA =
  "SHOPIFY_RESOURCE_PICKER_CALL_DATA";
export const SHOPIFY_RESOURCE_PICKER_SETTINGS_RESET_STATUS =
  "SHOPIFY_RESOURCE_PICKER_SETTINGS_RESET_STATUS";
export const SET_IN_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS =
  "SET_IN_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS";
export const SET_NEW_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS =
  "SET_NEW_DATA_RESOURCE_PICKER_CONVERT_COLLECTIONS";
export const SET_IN_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS =
  "SET_IN_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS";
export const SET_NEW_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS =
  "SET_NEW_DATA_RESOURCE_PICKER_CONVERT_PRODUCTS";
export const SET_RESOURCE_PICKER_OPEN = "SET_RESOURCE_PICKER_OPEN";
export const SET_DATA_RESOURCE_PICKER_FROM_SERVER =
  "SET_DATA_RESOURCE_PICKER_FROM_SERVER";

export const PATH_NAME = {
  ROOT: "/",
  LOGIN: "/login",
  IMAGE_OPTIMIZE_SETTINGS: "/image-optimize-settings",
  IMAGE_OPTIMIZE_HISTORY: "/image-optimize-history",
  META_TAGS: "/meta-tags",
  GOOGLE: "/google",
  GOOGLE_PERFORMANCE: "/google-performance",
  GOOGLE_SITEMAPS: "/google-sitemaps",
  SITEMAP: "/sitemap",
  SITE_VERIFICATIONS: "/site-verifications",
  NOT_FOUND_PAGES: "/not-found-pages",
  PAGE_SPEED_INSIGHTS: "/page-speed-insights",
  AUTOKETING_APPS: "/autoketing-apps",
  WELCOME: "/welcome",
  ADD_NEW: "/discount/add-new",
  ADD_NAME: "/discount/name",
  ADD_TIME_SETTING: "/discount/time",
  ADD_TIME_TARGET: "/discount/target",
  LIST_CAMPAIGN: "/list-campaign",
};

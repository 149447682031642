import axios from "axios";
import Cookies from "js-cookie";

function* callApiServiceCommon({
  url,
  method,
  params,
  appCookiesNameAuthorization,
}) {
  if (typeof params !== "object") {
    return yield;
  }
  const newArray = Object.keys(params);
  // POST
  if (method === "POST") {
    const bodyFormData = new FormData();
    newArray.forEach(element => {
      bodyFormData.append(element, params[element]);
    });

    return yield axios({
      method,
      url,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          window.sessionStorage.getItem(appCookiesNameAuthorization) ||
          Cookies.get(appCookiesNameAuthorization),
      },
    })
      .then(response => {
        return response.status === 200 ? response.data : "";
      })
      .catch(error => {
        console.log(error);
      });
  }
  // GET
  return yield axios({
    method: "get",
    url,
    params,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization:
        window.sessionStorage.getItem(appCookiesNameAuthorization) ||
        Cookies.get(appCookiesNameAuthorization),
    },
  })
    .then(response => {
      return response.status === 200 ? response.data : [];
    })
    .catch(() => {
      return [];
    });
}

export function* shopifyResourcePickerCallDataServer({
  targetType,
  first,
  query,
  after,
  shop,
  appCookiesNameAuthorization,
  apiUrlGraphqlQuery,
}) {
  let afterQuery = "";
  const shopCurrency = "";
  if (after && after.length > 10) {
    afterQuery = `, after: "${after}"`;
  }
  let graphql_query;
  switch (targetType) {
    case "collections":
      if (!first || first < 5 || first > 50) first = 50;
      graphql_query = `{
                          collections(first: ${first}, query: "${query}"${afterQuery}) {
                            edges {
                              node {
                                id
                                handle
                                title
                                image {
                                  id
                                  originalSrc
                                }
                              }
                              cursor
                            }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            endCursor
                            startCursor
                        }
                          }
                        }`;
      break;
    default:
      if (!first || first < 3 || first > 50) first = 50;
      graphql_query = `{
                          ${shopCurrency}
                          products(first: ${first}, query: "${query}"${afterQuery}) {
                            edges {
                              node {
                                id
                                handle
                                title
                                featuredMedia{
                                    preview {
                                        image {
                                            url
                                        }
                                    }
                                }
                              }
                              cursor
                            }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            endCursor
                            startCursor
                        }
                          }
                        }`;
  }

  const payload = {
    method: "POST",
    url: apiUrlGraphqlQuery,
    params: {
      graphql_query,
      shop_domain: shop,
      url:
        window.sessionStorage.getItem(appCookiesNameAuthorization) ||
        Cookies.get(appCookiesNameAuthorization),
    },
    appCookiesNameAuthorization,
  };
  return yield callApiServiceCommon(payload, targetType);
}

import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router";

// reducers
import { appReducer } from "./app";
import { imageOptimizeReducer } from "./image-optimize";
import { metaTagsReducer } from "./meta-tags";
import { googleReducer } from "./google";
import { sitemapReducer } from "./sitemap";
import { siteVerificationsReducer } from "./site-verifications";
import { notFoundPagesReducer } from "./not-found-pages";
import { pageSpeedInsightsReducer } from "./page-speed-insights";
import discountCampaignSettingsReducer from "./discountCampaign";
import shopifyResourcePicker from "../../components/AppResourcePicker/redurces/ShopifyResourcePicker";

export default history =>
  combineReducers({
    router: connectRouter(history),
    appReducer,
    imageOptimizeReducer,
    metaTagsReducer,
    googleReducer,
    sitemapReducer,
    siteVerificationsReducer,
    notFoundPagesReducer,
    pageSpeedInsightsReducer,
    discountCampaignSettingsReducer,
    shopifyResourcePicker,
  });

import { all } from "redux-saga/effects";

// sagas
import AppSaga from "./app";
import ImageOptimizeSaga from "./image-optimize";
import MetaTagsSaga from "./meta-tags";
import GoogleSaga from "./google";
import SitemapSaga from "./sitemap";
import SiteVerificationsSaga from "./site-verifications";
import NotFoundPagesSaga from "./not-found-pages";
import PageSpeedInsightsSaga from "./page-speed-insights";
import ShopifyResourcePicker from "../../components/AppResourcePicker/sagas/ShopifyResourcePicker";

export default function* rootSagas() {
  yield all([
    AppSaga(),
    ImageOptimizeSaga(),
    MetaTagsSaga(),
    GoogleSaga(),
    SitemapSaga(),
    SiteVerificationsSaga(),
    NotFoundPagesSaga(),
    PageSpeedInsightsSaga(),
    ShopifyResourcePicker(),
  ]);
}
